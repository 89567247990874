import { useTreatments } from '@splitsoftware/splitio-react';
import { TraderEventType } from '@utils/eventsConstant';
import { footer, footerNew } from '@utils/lists';
import { getNativeUtils, redirectToPage } from '@utils/nativeUtils';
import { ROUTE_NAMES } from '@utils/routes';
import { SplitIo } from '@utils/splitIo.enum';
import { FooterMenuName } from 'constants/types';
import { useGlobalContext } from 'contexts';
import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';
import Image from 'next/image';
import useLoanLaunch from 'hook/useLoanLaunch';
import NsplConsentPopup from '../nspl-consent-popup';
import Loader from '../Loader/Loader';
import { getStorageItem, getWin, setStorageItem } from '@utils/storage';
import { STORAGE_KEYS, StorageKeys } from '@utils/keys';
import BusinessProfileDialog from '../dialogs/business-profile-dialog/BusinessProfileDialog';
import { NFCCommercePageId } from '../DashboardIcons/OtherOfferings';
import useEvents from 'hook/useEvents';
import { isNativeFeatureAvailible } from '@utils/Utils';
import { FEATURE_RELEASE_VERSION } from '@utils/types/commontypes';
import {
  MANDI_NEWS_APP_VERSION,
  MANDI_NEWS_NEW_URL_VERSION,
} from 'constants/common';
import useHomepageExperiment from 'hook/useHomepageExperiment';

function Footer() {
  const { setShowBalanceClicked, ntcEnabled } = useGlobalContext();
  const sms_permission = getStorageItem(STORAGE_KEYS.SMS_PERMISSION);
  const smsCounter =
    Number(getStorageItem(STORAGE_KEYS.SMS_CONSENT_COUNTER)) || 0;
  const { smsLimitSet } = useGlobalContext();

  const selectedFooter = getStorageItem(STORAGE_KEYS.SELECTED_FOOTER) || 'Home';

  const isStaffView = getStorageItem('isStaffView');
  const { triggerEvent } = useEvents();

  const isNew = getStorageItem(STORAGE_KEYS.NEW_DASHBOARD);

  const { showMandiNewsAsHomePage } = useHomepageExperiment();

  const router = useRouter();
  const launchLoan = useLoanLaunch();
  const treatments = useTreatments([
    SplitIo.ScanPay,
    SplitIo.HomeIconFooter,
    SplitIo.TransactionHpFooter,
    SplitIo.LoansHpFooter,
    SplitIo.PartiesHpFooter,
    SplitIo.CommerceHpFooter,
    SplitIo.PartiesTierBadge,
    SplitIo.NFCCommerce,
    SplitIo.view_ninja_one,
    SplitIo.Mandi_News,
    SplitIo.NTCLoadMatirxLazily,
    SplitIo.sms_home_page,
  ]);

  const showNTC =
    ntcEnabled || getStorageItem(STORAGE_KEYS.NTC_ENABLED) || false;

  const footerClassName =
    isStaffView && isNew && showNTC
      ? 'tw-fixed tw-bottom-0 tw-z-10 tw-grid tw-h-16 tw-w-full tw-grid-cols-4 tw-items-center tw-gap-[10px] tw-border-2 tw-border-solid tw-bg-white'
      : isStaffView
      ? 'tw-fixed tw-bottom-0 tw-z-10 tw-grid tw-h-16 tw-w-full tw-grid-cols-2 tw-items-center tw-gap-[10px] tw-border-2 tw-border-solid tw-bg-white'
      : isNew && showNTC
      ? 'tw-fixed tw-bottom-0 tw-z-10 tw-grid tw-h-20 tw-w-full tw-grid-cols-5 tw-items-center tw-gap-[10px] tw-border-2 tw-border-solid tw-bg-white tw-pb-[20px] tw-pt-4'
      : 'tw-fixed tw-bottom-0 tw-z-10 tw-grid tw-h-16 tw-w-full tw-grid-cols-4 tw-items-center tw-gap-[10px] tw-border-2 tw-border-solid tw-bg-white';

  const footerlist = useMemo(() => {
    const footerList = isNew ? footerNew : footer;
    return footerList().filter((item) => {
      const { key, staffAccess, menuName } = item;
      let hasAccess = true;
      let hasStaffAccess = true;
      if (isStaffView) {
        hasStaffAccess = Boolean(staffAccess);
      }
      if (key) {
        hasAccess = treatments[key].treatment === 'on';
      }
      if (menuName === 'NTC') {
        hasAccess = showNTC;
      }

      return hasAccess && hasStaffAccess;
    });
  }, [isStaffView, treatments, showNTC]);

  const footerHandler = async (url: string, menuName: FooterMenuName) => {
    const androidVersion = +(
      getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION) || 0
    );
    const isAndroid = getStorageItem(STORAGE_KEYS.IS_ANDROID);
    setStorageItem(STORAGE_KEYS.SELECTED_FOOTER, menuName);
    setShowBalanceClicked(false);
    if (menuName == 'Commerce') {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: 'Com_initiate',
      });
    }
    if (menuName === 'Home') {
      router.push(ROUTE_NAMES.DASHBOARD);
      return;
    } else if (menuName === 'Transactions') {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: 'Payment_statement_view',
      });
      router.push(ROUTE_NAMES.TRANSACTION_HISTORY);
      return;
    } else if (menuName === 'Parties') {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: 'Choose_party_initiate',
      });
      router.push(ROUTE_NAMES.PARTIES);
      return;
    } else if (menuName === 'Loans') {
      triggerEvent(TraderEventType.Generic_event, {
        eventName: 'Apply_loans_footer_initiate',
      });
    }
    if (
      menuName == 'Commerce' &&
      treatments[SplitIo.NFCCommerce].treatment === 'on'
    ) {
      redirectToPage(
        process.env.NEXT_PUBLIC_NFC_COMMERCE_URL || '',
        `Commerce-${NFCCommercePageId}`
      );
      return;
    }

    if (menuName === 'Loans') {
      if (launchLoan.isConsented) {
        launchLoan.pgFetchMultipleProfile();
      } else {
        launchLoan.setShowConsentPopup(true);
      }
      return;
    }

    // mandinews and ntc redirection check and events

    if (menuName === 'Mandi News') {
      if (androidVersion > MANDI_NEWS_APP_VERSION && isAndroid) {
        if (!showMandiNewsAsHomePage) {
          setStorageItem(
            STORAGE_KEYS.MANDI_NEWS_TIMESTAMP as StorageKeys,
            Date.now()
          );
        }
        const url =
          androidVersion >= MANDI_NEWS_NEW_URL_VERSION
            ? process.env.NEXT_PUBLIC_MANDI_NEWS
            : process.env.NEXT_PUBLIC_MANDI_NEWS_OLD;
        redirectToPage(url as string, 'MandiNews', '');
        return;
      } else {
        const url = process.env.NEXT_PUBLIC_MANDI_NEWS_OLD;
        redirectToPage(url as string, 'MandiNews', getWin().localStorage);
        return;
      }
    }

    if (menuName === 'NTC') {
      if (isNativeFeatureAvailible(FEATURE_RELEASE_VERSION.NINJA_ONE)) {
        if (
          androidVersion > 187 &&
          smsLimitSet &&
          treatments[SplitIo.sms_home_page].treatment === 'on'
        ) {
          if (!sms_permission && smsCounter < 2) {
            router.push({
              pathname: ROUTE_NAMES.CONSENT_SMS,
              query: { app: 'NTC' },
            });
          } else if (sms_permission) {
            getNativeUtils()?.askSMSReadPermission();
            getNativeUtils()?.openWebview(
              `${process.env.NEXT_PUBLIC_NINJA_ONE_URL}/buyer/dashboard`,
              'NinjaOneMfe',
              {
                ...getWin().localStorage,
                [STORAGE_KEYS.NTC_MATRIX_LAZYLOAD]:
                  treatments[SplitIo.NTCLoadMatirxLazily].treatment === 'on',
              }
            );
          } else {
            getNativeUtils()?.openWebview(
              `${process.env.NEXT_PUBLIC_NINJA_ONE_URL}/buyer/dashboard`,
              'NinjaOneMfe',
              {
                ...getWin().localStorage,
                [STORAGE_KEYS.NTC_MATRIX_LAZYLOAD]:
                  treatments[SplitIo.NTCLoadMatirxLazily].treatment === 'on',
              }
            );
          }
        } else {
          getNativeUtils()?.openWebview(
            `${process.env.NEXT_PUBLIC_NINJA_ONE_URL}/buyer/dashboard`,
            'NinjaOneMfe',
            {
              ...getWin().localStorage,
              [STORAGE_KEYS.NTC_MATRIX_LAZYLOAD]:
                treatments[SplitIo.NTCLoadMatirxLazily].treatment === 'on',
            }
          );
        }
        return;
      }
    }
    redirectToPage(url, menuName);
  };

  return (
    <>
      <div
        className={footerClassName}
        style={{ boxShadow: isNew ? '0px -3px 16px 0px #0000001A' : '' }}
      >
        {footerlist.map(({ menuName, icon, activeIcon, url, name }) => (
          <div
            key={menuName}
            onClick={() => {
              footerHandler(url, menuName);
            }}
          >
            <div className="tw-flex tw-items-center tw-justify-center ">
              <Image
                alt=""
                className=""
                height={isNew ? 24 : 40}
                src={selectedFooter === menuName ? activeIcon : icon}
                width={isNew ? 24 : 40}
              />
            </div>
            <span
              className={`tw-flex tw-items-center tw-justify-center tw-text-center ${
                isNew
                  ? 'bodyLabel tw-mt-1 tw-text-lightBlackText'
                  : 'text-gray-600 tw-text-[10px]'
              } ${
                selectedFooter === menuName
                  ? isNew
                    ? 'tw-text-ninja-primary-600'
                    : 'tw-text-primary-600'
                  : ''
              }`}
            >
              {name}
            </span>
          </div>
        ))}
      </div>

      {launchLoan.loader && <Loader />}

      <NsplConsentPopup
        isCheckBoxRequired={true}
        isDisplayConsentPopup={launchLoan.showConsentPopup}
        isNsplConsent={true}
        onConsentProceed={launchLoan.onConsentProceed}
      />
      <NsplConsentPopup
        buttonLabel={'OK'}
        isCheckBoxRequired={false}
        isDisplayConsentPopup={launchLoan.showNSPLRedirectPopup}
        onConsentProceed={launchLoan.nsplRedirectOk}
      />

      <BusinessProfileDialog />
    </>
  );
}

export default memo(Footer);
